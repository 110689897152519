// 本地开发环境配置

export default {
  SERVER_URL: "http://ttfs.154basejy.com", // 后端服务域名
  DEPLOY_DIR: "/", // 项目部署的目录
  APP_URL: "http://localhost:8001", // 本项目访问地址
  H5_STATIC_SERVER_URL: "https://tencent.dev-env.basejy.com", // h5页面访问地址
  WX_APPID: "wx99bc4493f44c706f", // 微信公众号appid
  WX_MP_APP_ID: "wx2b5870e22df01aff", // 微信小程序appid
  JW_APP_URL: "http://localhost:8000",
};
