// 生产环境配置
import { isJwInlandApp, isJwIntlApp } from "../utils/app/env";

const deployDir = isJwInlandApp ? "/kuaicj/" : isJwIntlApp ? "/kuaicj/" : "/";
const appUrl = isJwInlandApp
  ? "https://jw.basejy.com/kuaicj"
  : isJwIntlApp
  ? "https://edu.basejy.com/kuaicj"
  : "https://kuaicj.basejy.com";
const JW_APP_URL = isJwInlandApp
  ? "https://jw.basejy.com"
  : isJwIntlApp
  ? "https://edu.basejy.com"
  : "https://jw.basejy.com";

export default {
  SERVER_URL: "https://api.basejy.com", // 后端服务域名
  DEPLOY_DIR: deployDir, // 项目部署的目录
  APP_URL: appUrl, // 本项目访问地址
  H5_STATIC_SERVER_URL: "https://h5.basejy.com", // h5页面访问地址
  WX_APPID: "wxae050b3b7db880a5", // 微信公众号appid
  WX_MP_APP_ID: "wx2b5870e22df01aff", // 微信小程序appid
  JW_APP_URL: JW_APP_URL,
};
