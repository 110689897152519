import process from "process";
import devConstants from "./constants.development";
import testConstants from "./constants.test";
import prdConstants from "./constants.production";
import genStorageKeyByAppType from "../utils/app/genStorageKeyByAppType";

const envConstantsMap = {
  production: prdConstants,
  test: testConstants,
  development: devConstants,
};

// @ts-ignore
const envConstants = envConstantsMap[process.env.DEPLOY_ENV] || devConstants;

export const {
  SERVER_URL,
  DEPLOY_DIR,
  APP_URL,
  H5_STATIC_SERVER_URL,
  WX_APPID,
  WX_MP_APP_ID,
  JW_APP_URL,
} = envConstants;

export const isPro = process.env.DEPLOY_ENV === 'production';
export const isTest = process.env.DEPLOY_ENV === 'test';

// node服务，用于报告pdf文件导出
export const NODE_SERVER_URL = isPro
  ? 'https://api.basejy.com/kuaicj-pdf'
  : isTest
  ? 'http://ttfs.146basejy.com:8287'
  : 'http://localhost:3000';

// 倍思家长小程序appid
export const JZ_WX_MP_APP_ID = 'wxb31a32b804116817';

// 后端服务前缀
export const BASE_SYSTEM_USER = '/base-system-user';
export const SCHOOL_SERVICE = '/base-school';
export const TEACHER_SERVICE = '/base-school-teacher';
export const SUPPORT_SERVICE = '/ttfs-support';
export const SCHOOL_COURSE = '/base-school-course';
export const BASE_SCHOOL_COURSE = '/base-school-course';
export const BASE_SCHOOL_TEACHER = '/base-school-teacher';
export const BASE_SCHOOL = '/base-school';
export const SCHEDULING_ENGINE = '/schedule/engine/scheduleproject';
export const BASE_EXCEL_ENGINE = '/base-excel-engine';
export const BASE_ANALYSIS = '/base-analysis';
export const BASE_SUPPORT = '/base-support';
export const BASE_USER_BEHAVIOR = '/base-user-behavior';
export const BASE_SALES = '/base-sales';

// 前端localStorage相关Key
export const USER_TOKEN = genStorageKeyByAppType('user_token'); // token
export const USER_INFO = genStorageKeyByAppType('user_info'); // 用户信息
export const SCHOOL_INFO = genStorageKeyByAppType('school_info'); // 学校信息
export const VISITOR_FROM = genStorageKeyByAppType('visitor_from');
export const VISITOR_REF = genStorageKeyByAppType('visitor_ref');

export const KUAICJ_STEP_GUIDE = 'KUAICJ_STEP_GUIDE';
export const KUAICJ_DATA_EXAMPLE = 'KUAICJ_DATA_EXAMPLE';
export const KUAICJ_MENU_GUIDE = 'KUAICJ_MENU_GUIDE';
export const KUAICJ_SCORE_ONLINE_EXAMPLE = 'KUAICJ_SCORE_ONLINE_EXAMPLE';
export const KUAICJ_TEMPLATE_COMMENT_GUIDE = 'KUAICJ_TEMPLATE_COMMENT_GUIDE';
export const KUAICJ_TEAM_ID = 'KUAICJ_TEAM_ID';
export const SCREEN_SCALE_TIP_MODAL = 'SCREEN_SCALE_TIP_MODAL'; // 屏幕缩放提示弹窗
export const BATCH_COPY_IMPORT_TIP_NOT_ANYMORE = 'batch_copy_import_tip_not_anymore';
